import * as helpers from './helpers';
import { extend, override } from 'flarum/extend';
import app from 'flarum/app';
import Drawer from 'flarum/utils/Drawer';
import DiscussionPage from 'flarum/components/DiscussionPage';

app.initializers.add('freelook-extension', app => {

  extend(Drawer.prototype, 'show', (vdom) => {
    document.body.addEventListener('touchmove', helpers.preventDefault, {passive: false});
  });

  extend(Drawer.prototype, 'hide', (vdom) => {
    document.body.removeEventListener('touchmove', helpers.preventDefault);
  });

  extend(DiscussionPage.prototype, 'show', function(vdom) {
    let self = this;
    let locationHash = window.location.hash;
    let offset = 60;

    $('a[href^="#"]').click(function(e){
      helpers.preventDefault(e);
      let $el = $(`a[href="${$(this).attr('href')}"]`).first();
      setTimeout(() => {
        window.location.hash = $el.attr('href');
      }, helpers.defaultTimeout);
      helpers.toTop($el, offset);
    }).removeAttr('target').addClass('hash fa fa-link');

    if(!!locationHash) {
      setTimeout(() => {
        helpers.toTop($(`a[href="${locationHash}"]`), offset);
      }, helpers.defaultTimeout);
    }

    ["javascript", "js", "html"].map((type) => {
      $(`pre>code.language-${type}`).each(function(index) {
        let parent = $(this).parent();
        let panel = $('<div class="panel"></div>');
        parent.addClass('code').append(panel);
        ['play', 'edit'].map((name) => {
          panel.append(
            $(`<i class="code-${name} fa fa-${name}" data-index="${index}" data-type="${type}"></i>`)
          );
        });
      });
    });

    $('pre.code')
    .on("click, mouseup", ".code-play", function(e) {
      helpers.fullStop(e);
      let content = helpers.getDiscussionContent(self.discussion);
      if(!!content) {
        let code = helpers.getBlockContent(this, content);
        helpers.runCode(this, code);
      }
    })
    .on("click, mouseup", ".code-edit", function(e) {
      helpers.fullStop(e);
      let content = helpers.getDiscussionContent(self.discussion);
      if(!!content) {
        let code = helpers.getBlockContent(this, content);
        helpers.editCode(this, code);
      }
    })
    .on("click, mouseup", ".code-close", function(e) {
      helpers.fullStop(e);
      let parentCode = $(this).closest('.code');
      helpers.closeCode(parentCode);
    });

  });

});
