export const defaultTimeout = 1000;
export const animationTimeout = 333;

export const toTop = (el, offset) => {
  let elOffset = $(el).offset();
  if(!!elOffset) {
    $('html, body').animate({
      scrollTop: elOffset.top - (offset || 0)
    }, animationTimeout);
  }
};

export const preventDefault = (e) => {
  e && e.preventDefault();
};

export const stopPropagation = (e) => {
  e && e.stopPropagation();
};

export const fullStop = (e) => {
  stopPropagation(e);
  preventDefault(e);
};

export const arrayFrom = (data) => {
  return [].slice.call(data);
};

export const matchAll = function(regexp, s) {
  let matches = [];
  s.replace(regexp, function() {
    let arr = arrayFrom(arguments, 0);
    let extras = arr.splice(-2);
    arr.index = extras[0];
    arr.input = extras[1];
    matches.push(arr);
  });
  return matches.length ? matches : null;
};

export const matchAllContent = (content, type) => {
  let pattern = `\`{3,}${type}\n((.|\n|\r)*?)\n\`{3,}`;
  return matchAll(new RegExp(pattern, "gim"), content);
};

export const getElementData = (el) => {
  return $(el).data() || {};
};

export const getDiscussionContent = (discussion) => {
  if (discussion) {
    let firstPost = (discussion.posts() || [])[0];
    if(firstPost) {
      return firstPost.content();
    }
  }
};

export const getBlockContent = (el, content) => {
  let data = getElementData(el);
  let index = data.index;
  let type = data.type;
  if(typeof index === 'number' && type) {
    let matchedArray = matchAllContent(content, type);
    if(!!matchedArray) {
      let indexedMatch = matchedArray[index];
      if(!!indexedMatch) {
        return indexedMatch[1];
      }
    }
  }
};

export const codeByType = (type, code) => {
  switch(type) {
    case 'javascript':
    case 'js':
      return `<script>${code}</script>`;
    case 'html':
      return code;
  }
};

export const isCodeVisual = (type) => {
  return type === 'html';
};

export const runCode = (el, code) => {
  let type = getElementData(el).type;
  let codeWrap = codeByType(type, code);
  if (!!codeWrap) {
    let isVisual = isCodeVisual(type);
    let parentCode = $(el).closest('.code');
    closeCode(parentCode);
    if(isVisual) {
      parentCode.append(`<i class="code-close fa fa-times"></i>`);
    }
    parentCode.append(
      $("<iframe></iframe>")
      .addClass(type)
      .attr('srcdoc', codeWrap)
    );
  }
};

export const closeCode = (el) => {
  el.find('.code-close').remove();
  el.find('iframe').remove();
};

export const editCode = (el, code) => {
  let type = getElementData(el).type;
  let form = document.createElement("form");
  form.action = "https://plnkr.co/edit/?p=preview";
  form.method = "POST";
  form.target = "_blank";
  let textarea = document.createElement("textarea");
  textarea.name = "files[index.html]";
  textarea.value = codeByType(type, code);
  form.appendChild(textarea);
  let input = document.createElement("input");
  input.name = "description";
  input.value = "Fork from " + window.location;
  form.appendChild(input);
  document.body.appendChild(form);
  form.submit();
  form.remove();
};